import React, { useEffect, useState } from 'react';
import { AddButtonUi } from '../Interface';
import CrmTaskDialog from './CrmTaskDialog';
import crmTaskService from '../../service/crmTask.service';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import dayjs from 'dayjs';

const NextEvent = ({ disabled, selectedValues, setSelectedValues, setRefetchTable }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [taskChanged, setTaskChanged] = useState(false);
  const [parentData, setParentData] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);

  useEffect(() => {
    if (taskChanged) {
      getCrmTaskById();
      setRefetchTable(true);
    }
  }, [taskChanged]);

  useEffect(() => {
    if (selectedValues.nextEvents && selectedValues.nextEvents.length > 0) {
      setRows(selectedValues.nextEvents);
    } else {
      setRows([]);
    }
  }, [selectedValues.nextEvents]);

  const fetchTaskById = (id, onSuccess) => {
    crmTaskService.getTaskById(id).then((data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    });
  };

  const getCrmTaskById = () => {
    if (selectedValues.id) {
      fetchTaskById(selectedValues.id, (data) => {
        setSelectedValues(data);
        setTaskChanged(false);
        setParentData((prev) => ({
          ...prev,
          id: data?.id,
          duration: 1,
          responsiblePersonId: data?.responsiblePersonId,
          projectId: data?.projectId,
        }));
      });
    }
  };

  const getTaskById = (row) => {
    fetchTaskById(row.id, (data) => {
      setSelectedRow(data);
      setIsDialogOpen(true);
    });
  };

  const handleAdd = () => {
    getCrmTaskById();
    setIsDialogOpen(true);
  };

  return (
    <div>
      <AddButtonUi disabled={disabled || !selectedValues.id} onClick={handleAdd} />
      <div>
        <table className="border w-full">
          <thead className="bg-tableHeaderColor">
            <tr className="grid grid-cols-5">
              <th className="border p-3">Időpont</th>
              <th className="border p-3 col-span-2">Esemény neve</th>
              <th className="border p-3">Felelős</th>
              <th className="border p-3">Megtekint</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr
                key={index}
                className={
                  index % 2 === 0
                    ? 'bg-gray-100 grid grid-cols-5 hover:bg-[#EDF4FB] cursor-pointer'
                    : 'grid grid-cols-5 hover:bg-[#EDF4FB] cursor-pointer'
                }
                onDoubleClick={() => getTaskById(row)}
              >
                <td className="border py-2 text-center">{dayjs(row.startDate).format('YYYY.MM.DD. HH:mm')}</td>
                <td className="border py-2 text-center col-span-2">{row.name}</td>
                <td className="border py-2 text-center">{row?.responsiblePerson?.userName}</td>
                <td className="border py-2 text-center">
                  <RemoveRedEyeIcon color="black" onClick={() => getTaskById(row)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CrmTaskDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        selectedValues={selectedValues}
        setTaskChanged={setTaskChanged}
        parentData={parentData}
        task={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default NextEvent;
