import { useMemo, useState } from 'react';

import { Field } from 'formik';

import DynamicPropertyMain from '../DynamicProperty/DynamicPropertyMain';
import { TabVisibilityConfig } from '../../config/tabVisibilityConfig';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import projectV2Service from '../../service/projectV2.service';
import { FormErrorUI, FormLabelUI, InputUI, TabsUI, FileTabUI, DeleteDialogUI } from '../Interface';
import ProjectCommunication from './ProjectCommunication';
import ProjectContact from './ProjectContact';
import ProjectResourceOneTable from './Resources/ResourceOneTable';
import ProjectResourceTwoTable from './Resources/ResourceTwoTable';
import ProjectResourceThreeTable from './Resources/ResourceThreeTable';
import ProjectResourceFourTable from './Resources/ResourceFourTable';
import ProjectResourceFiveTable from './Resources/ResourceFiveTable';
import ProjectSimpleTaskHistory from './ProjectSimpleTaskHistory';
import CheckLists from '../CheckList/CheckListTab/CheckLists';

const DescriptionPanel = ({ disabled, errors, values }) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="projectDescription"
            as={InputUI}
            className={`${disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Leírás" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.projectDescription} />}
            disabled={disabled}
            InputLabelProps={{ shrink: values.projectDescription !== '' }}
            multiline
            rows={10}
          />
        </div>
      </div>
    </div>
  );
};

const ProjectTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [removeElement, setRemoveElement] = useState(null);
  const [deleteDialog, setDeletDialog] = useState(false);

  const handleUpload = (files) => {
    if (!props.selectedValues.projectId) {
      return addNotification({
        content: 'Nincs kiválasztott elem!',
        type: NotificationType.ERROR,
      });
    }
    projectV2Service.uploadFile(files, props.selectedValues.projectId, props.user.userId).then((data) => {
      props.setSelectedValues((prev) => ({ ...prev, projectAttachment: data }));
    });
  };

  const handleDelete = (data) => {
    setRemoveElement(data.id);
    setDeletDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeletDialog(false);
    props.setRefetchTable(null);
  };
  const handleRemoveElement = (data) => {
    projectV2Service.deleteFile(removeElement, props.user.userId).then((data) => {
      props.setSelectedValues((prev) => ({ ...prev, projectAttachment: data }));
      hideDeleteDialog();
    });
  };

  const tabs = [
    {
      id: 0,
      name: 'Tulajdonságok',
      content: <DynamicPropertyMain {...props} />,
      key: 'PROPERTIES',
    },
    {
      id: 1,
      name: 'Checklista',
      content: <CheckLists {...props} />,
      key: 'CHECKLIST',
    },
    {
      id: 2,
      name: 'Megjegyzés',
      content: <DescriptionPanel {...props} />,
      key: 'DESCRIPTION',
    },
    {
      id: 3,
      name: props.submenuNames[0],
      content: <ProjectResourceOneTable {...props} />,
      key: 'RESOURCE_ONE',
    },
    {
      id: 4,
      name: props.submenuNames[1],
      content: <ProjectResourceTwoTable {...props} />,
      key: 'RESOURCE_TWO',
    },
    {
      id: 5,
      name: props.submenuNames[2],
      content: <ProjectResourceThreeTable {...props} />,
      key: 'RESOURCE_THREE',
    },
    {
      id: 6,
      name: 'Kapcsolattartó',
      content: <ProjectContact {...props} />,
      key: 'CONTACT',
    },
    {
      id: 7,
      name: 'Csatolmányok',
      content: (
        <FileTabUI
          disabled={props.disabled || !props.selectedValues.projectId}
          savedFiles={props.selectedValues.projectAttachment ?? []}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
        />
      ),
      key: 'CONTACT',
    },
    {
      id: 8,
      name: 'Kommunikáció',
      content: <ProjectCommunication {...props} />,
      key: 'COMMUNICATION',
    },
    {
      id: 9,
      name: props.submenuNames[3],
      content: <ProjectResourceFourTable {...props} />,
      key: 'RESOURCE_FOUR',
    },
    {
      id: 10,
      name: props.submenuNames[4],
      content: <ProjectResourceFiveTable {...props} />,
      key: 'RESOURCE_FIVE',
    },
    {
      id: 11,
      name: 'Projekt történet',
      content: <ProjectSimpleTaskHistory {...props} />,
      key: 'PROJECT_HISTORY',
    },
  ];

  const visibleTabs = useMemo(() => {
    const tabVisibilityMap = props.params.PROJECT_TAB_FIELDS
      ? JSON.parse(props.params.PROJECT_TAB_FIELDS).reduce((acc, field) => {
          const [key, value] = Object.entries(field)[0];
          acc[key] = value;
          return acc;
        }, {})
      : {};

    return tabs.filter((tab) => {
      if (!props.canEdit && props.infoPanel && TabVisibilityConfig[tab.key]?.hideWhenCanEditFalse) {
        return false;
      }
      if (tab.key === 'PROPERTIES') {
        return true;
      }
      if (!props.params.PROJECT_TAB_FIELDS) {
        return true;
      }
      if (tab.key.startsWith('RESOURCE_')) {
        const resourceIndex = parseInt(tab.key.split('_')[1]) - 1;
        return (
          (!tabVisibilityMap[tab.key] || tabVisibilityMap[tab.key] === 'Y') &&
          props.submenuNames[resourceIndex] !== null
        );
      }

      return !tabVisibilityMap[tab.key] || tabVisibilityMap[tab.key] === 'Y';
    });
  }, [props.params.PROJECT_TAB_FIELDS, tabs, props.submenuNames]);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {visibleTabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
      <DeleteDialogUI handleRemoveElement={handleRemoveElement} show={deleteDialog} onHide={() => hideDeleteDialog()} />
    </div>
  );
};

export default ProjectTabs;
