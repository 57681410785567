import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'simpletask';

const simpletaskService = {
  getTasks: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/tasks`,
      data,
    });
  },

  getGridTasks: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/gridtasks`,
      data,
    });
  },

  getTaskById: (simpleTaskId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${simpleTaskId}`,
    });
  },

  getMyTodoPriority: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/mytodo/priority`,
    });
  },

  getMyTodoStatus: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/mytodo/status`,
    });
  },

  updateStatus: (data) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/status`,
      data,
    });
  },

  createSimpleTask: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateSimpleTask: (data, simpleTaskId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${simpleTaskId}`,
      data,
    });
  },

  deleteSimpleTask: (data, simpleTaskId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${simpleTaskId}`,
      data,
    });
  },

  addRespPersonToList: (data, simpleTaskId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/respPersonList/${simpleTaskId}`,
      data,
    });
  },

  deleteRespPersonFromList: (personListId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/respPersonList/${personListId}`,
    });
  },

  updateRespPersonList: (data, respPersonListId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/updateRespPersonList/${respPersonListId}`,
      data,
    });
  },

  createTaskReport: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/taskReport`,
      data,
    });
  },

  uploadFile: (files, id, userId) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('userId', userId);

    return request({
      method: Methods.POST,
      resource: `${baseURL}/uploadFile/${id}`,
      formData: true,
      data: formData,
    });
  },

  deleteFile: (id, userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteFile/${id}`,
      data: { userId },
    });
  },
};

export default simpletaskService;
