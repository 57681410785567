import { useEffect, useState } from 'react';
import useMenus from '../context/MenuContext';
import useParam from '../context/ParamContext';
import masterDataService from '../service/masterData.service';
import { generateAutocompleteItems } from '../utils/helper';

export const useCrmTask = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [canEdit, setCanEdit] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [dynProps, setDynProps] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    type: [],
    status: [],
    user: [],
    project: [],
  });

  const [loading, setLoading] = useState(true);
  const [dynLoading, setDynLoading] = useState(true);
  const isLoading = loading || dynLoading;

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('crmTask');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    submenu && initDefaultValues();
  }, [submenu]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .usersToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          user: resp[2],
          project: resp[3],
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          type: generateAutocompleteItems(aData.type.typeItems, 'itemName', 'itemId'),
          user: generateAutocompleteItems(aData.user, 'userName', 'userId'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
        };
        setAutoCompleteOptions(acOptions);
        setLoading(false);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  return {
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    params,
    canEdit,
    rowSelectionModel,
    setRowSelectionModel,
    setCanEdit,
    refetchTable,
    setSubmenu,
    autocompleteData,
    autoCompleteOptions,
    setAutoCompleteOptions,
    dynProps,
    setDynProps,
    loading,
    dynLoading,
    setDynLoading,
    isLoading,
  };
};
