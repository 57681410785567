import { useEffect, useState } from 'react';

import { CheckboxUI, FileTabUI, TabsUI } from '../../Interface';
import DynamicPropertyMain from '../../DynamicProperty/DynamicPropertyMain';
import ResourceCommunication from './ResourceCommunication';
import ExcelImportTab from '../../Interface/ExcelImportTab';
import useSaveView from '../../../hooks/useSaveView';
import useView from '../../../hooks/useView';

const PropertyGroups = (props) => {
  const { propertyGroups, groupState, user } = props;
  const { group, setGroup } = groupState;
  const saveView = useSaveView(user);
  const savedGroups = useView('resourceOnePage', user);

  const handleCheckboxChange = (id) => {
    setGroup((prevGroup) => {
      const isSelected = prevGroup.id.includes(id);
      const updatedIds = isSelected ? prevGroup.id.filter((groupId) => groupId !== id) : [...prevGroup.id, id];

      const newGroup = { ...prevGroup, id: updatedIds };

      saveView('resourceOnePage', { dynGroup: updatedIds });

      return newGroup;
    });
  };

  useEffect(() => {
    if (Object.keys(savedGroups).length > 0) {
      setGroup((prevGroup) => ({
        ...prevGroup,
        id: savedGroups.dynGroup,
      }));
    }
  }, [savedGroups]);

  return (
    <div className="flex -mt-5 flex-wrap">
      {propertyGroups.length > 0 &&
        propertyGroups.map((groupItem) => (
          <div key={groupItem.id}>
            <CheckboxUI
              label={groupItem.name}
              checked={group.id.includes(groupItem.id)}
              onChange={() => handleCheckboxChange(groupItem.id)}
            />
          </div>
        ))}
    </div>
  );
};

const ResoureOneTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleUpload = () => {};

  const tabs = [
    {
      id: 0,
      name: 'Tulajdonságok',
      content: (
        <div>
          <PropertyGroups {...props} />
          <DynamicPropertyMain {...props} />
        </div>
      ),
      key: 'PROPERTIES',
    },
    {
      id: 1,
      name: 'Kommunikáció',
      content: <ResourceCommunication {...props} />,
    },
    {
      id: 2,
      name: 'Csatolmányok',
      content: (
        <FileTabUI disabled={props.disabled || !props.selectedValues.id} savedFiles={[]} handleUpload={handleUpload} />
      ),
    },
    {
      id: 3,
      name: 'Adat import',
      content: <ExcelImportTab {...props} />,
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default ResoureOneTabs;
