import React, { useState, useEffect } from 'react';
import useUsers from '../../context/UsersContext';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI } from '../Interface';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Formik, Form } from 'formik';

import crmTaskService from '../../service/crmTask.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import CrmTaskTabs from './CrmTaskTabs';
import CrmTaskFields from './CrmTaskFields';
import { getDynVals } from '../../utils/DynHelper';

export const CrmTaskPage = ({ crmTaskState, dialog }) => {
  const {
    setRefetchTable,
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    canEdit,
    setRowSelectionModel,
    autoCompleteOptions,
    autocompleteData,
    dynProps,
    setDynProps,
    dynLoading,
    setDynLoading,
  } = crmTaskState;

  const { getUser, user } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [defaultValues, setDefaultValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  const def = {
    name: '',
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()).add(1, 'hour'),
    duration: 1,
    typeId: null,
    projectId: null,
    responsiblePersonId: null,
    statusId: null,
    description: '',
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          typeId: autocompleteData?.type?.defaultValue ?? null,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (user) {
      setDefaultValues((prev) => {
        return { ...prev, createdBy: user?.userId };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          user: user,
        };
      });
    }
  }, [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    duration: Yup.number().positive(),
    typeId: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    responsiblePersonId: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    description: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField || !dialog.isUpdate) {
      handleInsertObject(values);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const valuesToForm = () => {
    const {
      name,
      startDate,
      endDate,
      duration,
      typeId,
      responsiblePersonId,
      createdBy,
      statusId,
      projectId,
      description,
    } = selectedValues;

    setInitValues({
      name,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      duration,
      typeId,
      projectId,
      responsiblePersonId,
      statusId,
      createdBy,
      description,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
    });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      duration: parseFloat(values.duration),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      updatedBy: user.userId,
    };

    crmTaskService.updateTask(updData, selectedValues.id).then((_crmTask) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);

      if (dialog) {
        dialog.setDataChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      duration: parseFloat(values.duration),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      createdBy: user.userId,
      ...(dialog && !dialog.isUpdate ? { parentId: dialog.parentId } : {}),
    };

    crmTaskService
      .createTask(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel(data.id);

        if (dialog) {
          dialog.setDataChanged(true);
          dialog.handleClose();
        }
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    crmTaskService.deleteTask(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);

      if (dialog) {
        dialog.setDataChanged(true);
        dialog.handleClose();
      }
    });
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  return (
    <>
      <div className="h-full overflow-auto custom-scrollbar">
        <FormHeaderUI
          addClick={(!dialog || !dialog.isViewMode) && (!dialog ? onAddClick : null)}
          addDisabled={dialog?.isViewMode || !viewField || !canEdit}
          modClick={!dialog || dialog.isUpdate ? onModifyClick : null}
          modDisabled={!viewField || operatorsDisabled || !canEdit}
          deleteClick={!dialog || dialog.isUpdate ? onDeleteClick : null}
          deleteDisabled={!viewField || operatorsDisabled || !canEdit}
          copyClick={!dialog || dialog.isUpdate ? onCopyClick : null}
          copyDisabled={!viewField || operatorsDisabled || !canEdit}
        />

        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
          //validateOnMount={false}
        >
          {({ values, setFieldValue, errors, validateField, resetForm }) => (
            <Form>
              <CrmTaskFields
                submenuId={submenu}
                selectedValues={selectedValues}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                validateField={validateField}
                viewField={(!dialog || dialog?.isUpdate) && viewField}
                autoCompleteOptions={autoCompleteOptions}
              />

              <CrmTaskTabs
                values={values}
                errors={errors}
                submenuId={submenu}
                dynProperties={{ dynProps, setDynProps }}
                disabled={(!dialog || dialog?.isUpdate) && viewField}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setDefaultValues={setDefaultValues}
                setRefetchTable={setRefetchTable}
                user={user}
                dynLoadingState={{ dynLoading, setDynLoading }}
              />

              <FormButtonPanelUI
                onCancelClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setAddField(true);
                  resetForm();
                }}
                disabled={
                  dialog
                    ? dialog.isViewMode ||
                      (dialog.isUpdate && ((editButtonVisible && addField) || (!editButtonVisible && !addField)))
                    : (editButtonVisible && addField) || (!editButtonVisible && !addField)
                }
              />
            </Form>
          )}
        </Formik>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
